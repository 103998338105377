






















import { Component, Vue } from "vue-property-decorator";
import { uploadFileToOss } from "@/assets/js/Utils";

@Component
export default class OssUploader extends Vue {
  private file: any = { url: "" };
  private filename = "";

  handleChange(file: any): void {
    this.file = file;
    this.filename = file.name.replace(/\s\(|\)/g, "");
  }

  submit(): void {
    if (this.filename) {
      this.file.name = this.filename;
    }
    uploadFileToOss(
      this.file,
      "shoppingGuide/colorCards/",
      this.filename == ""
    ).then((res: any) => {
      console.warn(res);
      this.$message("上传成功！");
      alert("上传成功");
      window.location.reload();
    });
  }
}
